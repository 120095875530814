import { setGlobalAlert } from "components/ui/GlobalAlert/store";
import { useSelector, useDispatch } from "react-redux";

/**
 * Hook to check the access of a component based on the user's organization details.
 *
 * @param {string} component - The component to check access for.
 * @param {boolean} showAlert - Whether to show an alert if the user has reached their limit.
 * @returns {Object} An object containing information about the access check.
 */
const useAccessCheck = (component, showAlert = true) => {
    // Get dispatch function from Redux store
    const dispatch = useDispatch();

    // Get organization details from Redux store
    const organizationDetails = useSelector((state) => state.user?.organizationDetails);

    // If organization details are not available, return default values
    if (!organizationDetails) {
        return {
            remaining: 0,
            isUnderLimit: false,
            component,
            alert: "",
            alertType: "",
            closable: false,
            validTotal: 0,
            validUsed: 0,
        };
    }

    // Map of components to their total and used properties in organization details
    const componentsMap = {
        contacts: {
            totalProperty: "totalContacts",
            usedProperty: "usedContacts",
        },
        campaigns: {
            totalProperty: "totalCampaigns",
            usedProperty: "usedCampaigns",
        },
        groups: {
            totalProperty: "totalGroups",
            usedProperty: "usedGroups",
        },
        surveys: {
            totalProperty: "totalSurveys",
            usedProperty: "usedSurveys",
        },
        trigglies: {
            totalProperty: "totalTrigglies",
            usedProperty: "usedTrigglies",
        },
        messages: {
            totalProperty: "totalMessages",
            usedProperty: "usedMessages",
        },
        aiCredits: {
            totalProperty: "totalAICredits",
            usedProperty: "usedAICredits",
        },
    };

    // Add component name and plans which should check the access limit
    const planAccessMap = {
        contacts: ["Free"],
        messages: ["Free", "Tickle", "Turbo", "Booster"],
        aiCredits: ["Free", "Tickle", "Turbo", "Booster"],
        default: [],
        // default: ["Free", "Tickle", "Turbo", "Booster", "Enterprise"],  // To check every component un-comment this line and comment out the above lines
    };

    // Get the total and used properties for the component from the components map
    const { totalProperty, usedProperty } = componentsMap[component] || { totalProperty: "", usedProperty: "" };

    // Get the total and used values from the organization details
    const total = organizationDetails[totalProperty] ?? 0;
    const used = organizationDetails[usedProperty] ?? 0;

    // Convert total and used values to numbers if they are not already
    const validTotal = isNaN(total) ? 0 : total;
    const validUsed = isNaN(used) ? 0 : used;

    // Calculate the remaining number of components
    const remaining = validTotal - validUsed;

    // Check if the user is under their limit
    let isUnderLimit = true;

    // Get the plan names for the component from the plan access map
    const planNames = planAccessMap[component] || planAccessMap.default;

    // Check if the user has used up their limit
    isUnderLimit = validUsed <= validTotal;

    // Initialize variables for alert type, message, and closable flag
    let alertType = "";
    let alertMessage = "";
    let closable = true;

    // Set a threshold for the remaining number of components
    const threshold = validTotal * 0.1; // 10% threshold

    // Check if the remaining number of components is below the threshold
    if (remaining <= threshold && remaining > 0) {
        alertType = "warning";
        alertMessage = `You only have ${remaining} ${component} remaining.`;
        isUnderLimit = true;
    } else if (remaining <= 0) {
        alertType = "danger";
        alertMessage = `You have used up your ${component} limit. Please upgrade your plan to get more ${component}.`;
        closable = false;
        isUnderLimit = false;
    } else {
        // If remaining is more than 10%, don't show anything
        return { isUnderLimit: true };
    }
    // Prepare the data object with the access check information
    const data = {
        remaining,
        isUnderLimit,
        component,
        alert: alertMessage,
        alertType,
        closable,
        validTotal,
        validUsed,
    };

    // Check if the user should be shown an alert and if the plan allows for the component
    if (showAlert && planNames.includes(organizationDetails.planName) && !!componentsMap[component]) {
        // Dispatch the global alert action with the data object
        dispatch(setGlobalAlert(data));
    }

    // Return the access check data object
    return data;
};

export default useAccessCheck;
