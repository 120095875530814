import React from "react";
import {
    HiChartSquareBar,
    HiCurrencyDollar,
    HiOutlineBan,
    HiOutlineBookOpen,
    HiOutlineCash,
    HiOutlineChartPie,
    HiOutlineChatAlt,
    HiOutlineCode,
    HiOutlineColorSwatch,
    HiOutlineCurrencyDollar,
    HiOutlineDocumentDuplicate,
    HiOutlineDocumentText,
    HiOutlineHand,
    HiOutlineKey,
    HiOutlineLockClosed,
    HiOutlineLogout,
    HiOutlineOfficeBuilding,
    HiOutlinePaperAirplane,
    HiOutlineShare,
    HiOutlineShieldCheck,
    HiOutlineSpeakerphone,
    HiOutlineTemplate,
    HiOutlineTrendingUp,
    HiOutlineUserGroup,
    HiOutlineUsers,
    HiOutlineVariable,
    HiOutlineViewGridAdd,
    HiUser,
} from "react-icons/hi";
import { FiActivity } from "react-icons/fi";
import { SiGnuprivacyguard } from "react-icons/si";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { CgDisc, CgPoll } from "react-icons/cg";
import { PiUsersThreeBold } from "react-icons/pi";
import { TbBellCancel, TbSettingsAutomation, TbSettingsCode } from "react-icons/tb";
import { BsChatDots } from "react-icons/bs";
import { GiNetworkBars } from "react-icons/gi";
import { AiFillWarning, AiOutlineGlobal } from "react-icons/ai";
import { LuContact, LuCrown, LuLayoutDashboard } from "react-icons/lu";
import {
    MdHistoryToggleOff,
    MdLoyalty,
    MdOutlineEvent,
    MdOutlineSettings,
    MdOutlineSupervisedUserCircle,
    MdPhoneEnabled,
    MdSupportAgent,
} from "react-icons/md";
import { RiMailLockLine, RiMoneyDollarCircleLine, RiUserAddLine, RiVipCrown2Line } from "react-icons/ri";
import { IoChevronBackCircleOutline, IoConstructOutline } from "react-icons/io5";
import { GrRestaurant } from "react-icons/gr";
import { VscFeedback } from "react-icons/vsc";
import { SlDocs } from "react-icons/sl";
import { TiContacts } from "react-icons/ti";
import { FaRegSave } from "react-icons/fa";
import { IoPersonRemoveOutline } from "react-icons/io5";
import { FcSurvey } from "react-icons/fc";

const navigationIcon = {
    apps: <HiOutlineViewGridAdd />,
    dashboard: <LuLayoutDashboard />,
    crm: <HiOutlineUserGroup />,
    sales: <HiOutlineTrendingUp />,
    crypto: <HiOutlineCurrencyDollar />,
    knowledgeBase: <HiOutlineBookOpen />,
    account: <MdOutlineSettings />,
    uiComponents: <HiOutlineTemplate />,
    common: <HiOutlineColorSwatch />,
    feedback: <VscFeedback />,
    channels: <BsChatDots />,
    forms: <HiOutlineDocumentText />,
    navigation: <HiOutlinePaperAirplane />,
    graph: <HiOutlineChartPie />,
    authentication: <HiOutlineLockClosed />,
    signIn: <HiOutlineShieldCheck />,
    signUp: <TiContacts />,
    forgotPassword: <HiOutlineLockClosed />,
    resetPassword: <HiOutlineKey />,
    pages: <HiOutlineDocumentDuplicate />,
    welcome: <HiOutlineHand />,
    accessDenied: <HiOutlineBan />,
    guide: <HiOutlineBookOpen />,
    documentation: <HiOutlineDocumentText />,
    sharedComponentDoc: <HiOutlineShare />,
    utilsDoc: <HiOutlineVariable />,
    changeLog: <HiOutlineCode />,
    integration: <HiOutlineViewGridAdd />,
    analytics: <HiChartSquareBar />,
    company: <HiOutlineOfficeBuilding />,
    users: <RiMoneyDollarCircleLine />,
    profile: <HiUser />,
    billing: <HiCurrencyDollar />,
    activity: <FiActivity />,
    signOut: <HiOutlineLogout />,
    groups: <HiOutlineUserGroup />,
    campaigns: <HiOutlineSpeakerphone />,
    surveys: <CgDisc />,
    privacy: <SiGnuprivacyguard />,
    terms: <HiOutlineClipboardDocumentList />,
    growth: <GiNetworkBars />,
    segment: <PiUsersThreeBold />,
    resources: <SlDocs />,
    support: <MdSupportAgent />,
    warning: <AiFillWarning />,
    contacts: <LuContact />,
    triggly: <TbSettingsAutomation />,
    message_config: <TbSettingsCode />,
    number: <MdPhoneEnabled />,
    config: <AiOutlineGlobal />,
    verify: <RiMailLockLine />,
    builder: <IoConstructOutline />,
    join: <RiUserAddLine />,
    help: <HiOutlineChatAlt />,
    order: <GrRestaurant />,
    promo: <HiOutlineSpeakerphone />,
    event: <MdOutlineEvent />,
    loyalty: <MdLoyalty />,
    participant: <MdOutlineSupervisedUserCircle />,
    owner: <LuCrown />,
    admin: <RiVipCrown2Line />,
    history: <MdHistoryToggleOff />,
    cash: <HiOutlineCash />,
    unsubscribe: <TbBellCancel />,
    back: <IoChevronBackCircleOutline />,
    save: <FaRegSave />,
};

export default navigationIcon;
